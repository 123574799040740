import logo from './logo.svg';
import './App.css';
import React from 'react';

import {Routes, Route, Link, useSearchParams } from "react-router-dom";
import Expenses from "./tempComps/expenses";
import Invoices from "./tempComps/invoices";
import NotFound from "./tempComps/notFound";

import {useContext} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Context} from './LangConfig';
import CurrencyComp from './tempComps/CurrencyComp';

import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import ToastMessage from './tempComps/ToastMessage';

import SiblingComp1 from "./tempComps/SiblingComp1";
import SiblingComp2 from './tempComps/SiblingComp2';

import { OidcProvider, withOidcSecure } from '@axa-fr/react-oidc-context';
import { configurationAuth0, configurationIdentityServer, configurationAxentia } from './configurations.js';
import Auth from './tempComps/Auth';
import { Profile } from './tempComps/Profile';
import LoginCallbackComp from './tempComps/LoginCallbackComp';
import { ProtectedRoute } from './ProtectedRoute.js';
import AuthenticatedExComp from './tempComps/AuthenticatedExComp';
import AuthorizationExComp from './tempComps/AuthorizationExComp';

import { useAtom } from "jotai";
import { userAtom } from './store';

function App() {
  // i18n
  const context = useContext(Context);
  const intl = useIntl();

  // Toaster
  function notify () {
    toast(ToastMessage);
  } 

  let [searchParams, setSearchParams] = useSearchParams(); /* use these when? */

  // User state with Jotai
  const [user, setUser] = useAtom(userAtom);

  // To be replaced with oidc and jotai
  const handleLogin = () => setUser({ id: '1', name: 'robin', permissions: ['analyze'], roles: ['admin'] });
  const handleLogout = () => setUser(null);

  return (
    <div className="App">
      {user ? (<button onClick={handleLogout}>Sign Out</button>) :
      (<button onClick={handleLogin}>Sign In</button>)}
      
      <OidcProvider configuration={configurationAxentia}>
        {/* Oidc test */}
        <Auth />
        {user && <h4>Current user payload: {user.name}</h4>} {/*{user.id}, {user.name}, {user.permissions}, {user.roles}</h4>}*/}
        <p onClick={() => {setUser({ ...user, name: 'robyn'});}}>Modify User</p>
        <p onClick={() => setUser({ id: '1', name: 'robin', permissions: ['analyze'], roles: ['admin'] })}>Reset User</p>

        {/* Routing test */}
        <Link to="/invoices">Invoices</Link> |{" "}
        <Link to="/expenses">Expenses</Link> |{" "}
        <Link to="/authenticated">Authenticated</Link> |{" "}
        <Link to="/authorized">Authorized</Link> |{" "}
        <Link to="/profile">Profile</Link>
        
        <Routes>
          <Route index element={<Invoices />} />
          <Route path="/expenses" element={<Expenses />} />
          <Route path="/invoices" element={<Invoices />} />

          <Route path="/loginCallback2" element={<LoginCallbackComp />} /> {/* Use another component? */}
          <Route element={<ProtectedRoute isAllowed={!!user} />}>
            <Route path="/authenticated" element={<AuthenticatedExComp />} />
            <Route path="/profile" element={<Profile />} />
          </Route>
          <Route element={<ProtectedRoute isAllowed={user && user.permissions.includes('analyze')} />}>
            <Route path="/authorized" element={<AuthorizationExComp />} />
          </Route>
          
          <Route path="*" element={<NotFound />} />
        </Routes>
        {/* Translation test */}
        {/* Component examples */}
        <p>    
          <CurrencyComp currency={"SEK"}/>
          <CurrencyComp currency={"EUR"}/>
        </p>
        <p>
          <FormattedMessage
            id="myMessage" defaultMessage="This default message is using {ts}!"
            values={{ts: "props"}}
          />
        </p>
        {/* API example */}
        <p>
          {intl.formatMessage(
            {id: "myMessage", defaultMessage: "This default message is using {ts}!"}, 
            {ts: "props"}
          )}
        </p>
        {/* Switch language */}
        <p onClick={context.selectLanguage}>Current language: {context.locale}</p>
        {/* Toaster example */}
        <button onClick={notify}>Toast!</button>
        <ToastContainer/>
        {/* State manager example */}
        <SiblingComp1/>
        <SiblingComp2/>
      </OidcProvider> 
    </div>
  );
}

export default App;
