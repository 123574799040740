import {OidcSecure, useOidcAccessToken, useOidcIdToken, useOidcUser} from '@axa-fr/react-oidc-context';
import { useAtom } from "jotai";
import { userAtom } from '../store';
import { useSearchParams } from 'react-router-dom';

export default function LoginCallbackComp() {
  console.log('hej uffe');
  //console.log('loginCallbackComp is called');
  const{ idToken, idTokenPayload } = useOidcIdToken();
  //const [user, setUser] = useAtom(userAtom);
  //console.log("idTokenpayload", idTokenPayload);
  //setUser(idTokenPayload);

  const [searchParams] = useSearchParams();
  console.log(searchParams.entries());
  console.log(searchParams.get('code')); // 'name'

  return (
    <p>Setting new user in store...</p>
  );
}