
import { useAtom } from "jotai";
import { countAtom } from '../store';

export default function SiblingComp2() {
  const [count, setCount] = useAtom(countAtom);

  return (
    <>
      <button onClick={() => setCount(c => c - 1)}>-1</button>
      <button onClick={() => setCount(c => c + 1)}>+1</button>
    </>
  );
}