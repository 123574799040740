import { useAtom } from "jotai";
import { userAtom } from '../store';

export default function Invoices() {

  // User state with Jotai
  const [user, setUser] = useAtom(userAtom);

  // To be replaced with oidc and jotai
  const handleLogin = () => setUser({ id: '1', name: 'roben', permissions: ['analyze'], roles: ['admin'] });

  return (
    <main style={{ padding: "1rem 0" }}>
      <h2 onClick={handleLogin}>Invoices</h2>
    </main>
  );
}