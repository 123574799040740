import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
import Swedish from './/lang/sv.json';
import English from './/lang/en.json';

export const Context = React.createContext();

const local = navigator.language.split(/[-_]/)[0];
let lang;
if (local === 'en') {
	lang = English;
} else {
	lang = Swedish;
}

function LangConfig(props) {
	const [locale, setLocale] = useState(local);
	const [messages, setMessages] = useState(lang);
	function selectLanguage(e) {
		if (locale === 'en') {
			setLocale('sv');
			setMessages(Swedish);
		} else {
			setLocale('en');
			setMessages(English);
		}
	}
	return (
		<Context.Provider value={{ locale, selectLanguage }}>
			<IntlProvider messages={messages} locale={locale}>{props.children}</IntlProvider>
		</Context.Provider>
	);
}
export default LangConfig;