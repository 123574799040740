import React, {useEffect, useState} from 'react';

import {OidcSecure, useOidcAccessToken, useOidcIdToken, useOidcUser} from '@axa-fr/react-oidc-context';

const DisplayUserInfo = () => {
    const{ oidcUser, isOidcUserLoading, isLogged } = useOidcUser();

    if(isOidcUserLoading) {
        return <p>User Information are loading</p>
    }

    // Replaced with <ProtectedRoute/>
    /*
    if(!isLogged){
        return <p>you are not authentified</p>
    }*/

    return (
        <div className="card text-white bg-success mb-3">
            <div className="card-body">
                <h5 className="card-title">User information</h5>
                <p>{oidcUser == null && "You are not logged" }</p>
                {oidcUser != null && <p className="card-text">{JSON.stringify(oidcUser)}</p>}
            </div>
        </div>
    )
};

export const Profile = () => {

    /* from oidc-react example proj */
    const [oidcUser, setOidcUser] = useState(null);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUserInfoAsync = async () => {
            const res = await fetch("https://localhost:44362/connect/userinfo");
            if (res.status != 200) {
                return null;
            }
            return res.json();
        };
        let isMounted = true;
        fetchUserInfoAsync().then((userInfo) => {
            if(isMounted) {
                setLoading(false);
                setOidcUser(userInfo)
            }
        })
        return  () => {
            isMounted = false;
        };
    },[]);

    if(isLoading){
        return (<p>Loading</p>);
    }
    /********************************/

    return (
       <div className="container mt-3">
           <DisplayAccessToken/>
           <DisplayIdToken/>
           {/*<DisplayUserInfo/> // user endpoint not available */}
        </div>
    );
}

const DisplayAccessToken = () => {
    const{ accessToken, accessTokenPayload } = useOidcAccessToken();

    // Replaced with <ProtectedRoute/>
    /*
    if(!accessToken){
        return <p>you are not authentified</p>
    }
    */
    return (
        <div className="card text-white bg-info mb-3">
            <div className="card-body">
                <h5 className="card-title">Access Token</h5>
                <p style={{color:'red', "backgroundColor": 'white'}}>Please consider to configure the ServiceWorker in order to protect your application from XSRF attacks. "access_token" and "refresh_token" will never be accessible from your client side javascript.</p>
                {accessToken != null && <p className="card-text">Access Token: {JSON.stringify(accessToken)}</p>}
                {accessTokenPayload != null && <p className="card-text">Access Token Payload: {JSON.stringify(accessTokenPayload)}</p>}
            </div>
        </div>
    )
};


const DisplayIdToken =() => {
    const{ idToken, idTokenPayload } = useOidcIdToken();
    //const [user, setUser] = useAtom(userAtom);
    //console.log("idTokenpayload", idTokenPayload);
    //setUser(idTokenPayload);

    // Replaced with <ProtectedRoute/>
    /*
    if(!idToken){
        return <p>you are not authentified</p>
    }
    */
    
    return (
        <div className="card text-white bg-info mb-3">
            <div className="card-body">
                <h5 className="card-title">ID Token</h5>
                {idToken != null && <p className="card-text">IdToken: {JSON.stringify(idToken)}</p>}
                {idTokenPayload != null && <p className="card-text">IdToken Payload: {JSON.stringify(idTokenPayload)}</p>}
            </div>
        </div>
    );
}


export const SecureProfile = () => <OidcSecure><Profile /></OidcSecure>;