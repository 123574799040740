import {useOidc, useOidcAccessToken} from '@axa-fr/react-oidc-context';
import { useAtom } from "jotai";
import { userAtom } from '../store';

export default function Auth() {

  const { login, logout, isLogged} = useOidc();
  const [user, setUser] = useAtom(userAtom);

  return (
    <div>
      {!isLogged && <button type="button" className="btn btn-primary" onClick={() => {
        login('/profile');
      }}>Login</button>}
      {isLogged && <button type="button" className="btn btn-primary" onClick={() => {
        logout();
      }}>Logout</button>}
    </div>
  );
}